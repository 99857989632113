/**
 _buttons
*/

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;

  min-width: 11rem;
  height: 3.125rem;

  font-family: $font-heading;
  font-size: $font-size-small;

  margin: auto;

  font-weight: 500;
  line-height: 21px;
  text-align: center;

  padding: 0.5rem 1.5rem;

  border-radius: 50px;

  outline: none;
  border: none;

  transition: all 0.2s linear;

  color: $dark-400;

  &:hover:enabled {
    filter: brightness(0.9);
  }

  &:disabled {
    background-color: $gray;
    opacity: 1;
  }
}

.btn-purple-round {
  @extend .btn;
  background: $light-purple;
}

.btn-pink-round {
  @extend .btn;
  background: $light-pink;
}

.btn-blue-round {
  @extend .btn;
  background: $light-blue;
}

.btn-white-round {
  @extend .btn;
  background: $shape;
}

.btn-outline-black {
  @extend .btn;
  background-color: $shape !important;
  color: $dark-400 !important;
  font-size: $font-size-small !important;
  border: 1px solid $dark-400 !important;
  font-weight: 600 !important;
}

