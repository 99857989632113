$shape: #fff;
$dark-900: #000;
$dark-700: #221d1f;
$dark-500: #343434;
$dark-400: #424242;
$dark-300: #606060;
$dark: #1C1210;
$gray: #c4c4c4;
$gray85: #e1e1e1;
$light-gray: #6C757D;
$pink: #f7a5c9;
$pink20: #EB85A5;
$blue: #afe7ff;
$purple: #ead0fa;
$red: #fb9eaf;
$light-pink: #ffd0e5;
$light-purple: #ead0fa;
$light-blue: #dcf5fe;
$light-yellow: #fef7c7;
$pink-200: #F4E7FC;
$vibrant-purple: #CD7BFF;
$green: #008733;

$shadows: (
  'card': 0 0 5px rgba(0, 0, 0, 20%),
  'glamclub': 0 4px 4px rgba(0, 0, 0, 0.25),
  'checkout': 4px 4px 25px rgba(0, 0, 0, 0.1)
);

$font-heading: 'Rubik', sans-serif;
$font-base: 'Open Sans', sans-serif;
$font-secondary: 'Montserrat', sans-serif;
$font-size-xxlarge: 3.125rem;
$font-size-xlarge: 2.75rem;
$font-size-large: 1.75rem;
$font-size-xxmedium: 1.5rem;
$font-size-xmedium: 1.25rem;
$font-size-medium: 1.125rem;
$font-size-small: 1rem;
$font-size-xsmall: 0.75rem;


$font-size-description: 0.875rem;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;

// Media Queries
// ----------------------------------------------------------

$mq: (
  'super-sm-mobile': 281px,
  'sm-mobile': 320px,
  'lg-mobile': 480px,
  'tablet': 768px,
  'laptop': 1024px,
  'desktop': 1366px,
  'infinity': 1440px
);

// Functions
// ----------------------------------------------------------

@mixin mq($mq-value) {
  @media (min-width: map-get($mq, $mq-value)) {
    @content;
  }
}

@function shadow($shadow-value) {
  @return map-get($shadows, $shadow-value);
}
