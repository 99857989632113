@import "./variables";
@import "./buttons";
@import "/node_modules/@infrab4a/components-b4a/src/themes/glam.theme";

:root {
  //Instagram
  --svg-outline-next-arrow-instagram: url(./../../assets/img/svg/outline-next-arrow.svg);
  --svg-outline-previous-arrow-instagram: url(./../../assets/img/svg/outline-previous-arrow.svg);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $font-base;
  background: $shape;
  color: $dark-400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
textarea,
button {
  font-family: $font-heading;
  font-weight: 400;
}

ul {
  margin-bottom: 0;
  padding-left: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
  color: $dark-400;
  font-family: $font-heading;
  font-weight: 400;
}

h1 {
  font-size: $font-size-xlarge;
}

h2,
h3 {
  font-size: $font-size-xxmedium;
}

h4,
h5 {
  font-size: $font-size-xmedium;
}

button {
  cursor: pointer;
}

[disabled] {
  background-color: #C4C4C4 !important;
  cursor: not-allowed;
}

a {
  color: $dark-400;
  text-decoration: none;

  transition: all 0.2s linear;

  &:hover {
    color: $dark-700;
  }
}

// html {
//   @media (max-width: 1080px) {
//     font-size: 93.75%;
//   }

//   @media (max-width: 720px) {
//     font-size: 87.5%;
//   }
// }

/* Alerts */

.danger {
  border-color: $pink;
  color: $pink;

  font-size: $font-size-small;
}

.mp-values {
  .wrapper {
    &__title {
      margin-bottom: 40px;
      font-family: var(--font-secondary) !important;
    }

    &__button button {
      font-size: 1rem !important;
      font-weight: 500 !important;
      font-family: var(--font-secondary) !important;
    }
  }

  .content .circle {
    width: 128px;
    height: 178px;
  }
}
